import $axios from 'axios';

// 我的名片
export const findUserCenterApi = () => {
    const token = sessionStorage.getItem('token');
    return $axios({
        method: 'GET',
        url: "/user/findUserCenter",
        headers: {
            Authorization: token
        }
    })
}

// 分页查询
export const userPagingApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'GET',
        url: "/user/paging",
        params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 用户详情
export const userDetailsApi = (userId) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'GET',
        url: `/user/details/${userId}`,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 修改用户
export const userUpdateApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'PUT',
        url: "/user/update",
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 取消授权
export const cancelAuthorizationApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'DELETE',
        url: "/user/cancelAuthorization",
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 查询角色和部门下拉框
export const findRoleDeptSelectApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'GET',
        url: "/user/findRoleDeptSelect",
        params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 授权
export const authorizationApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'POST',
        url: "/user/authorization",
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 锁定或取消锁定
export const lockOrUnlockApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'PUT',
        url: "/user/lockOrUnlock",
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 注册用户
export const registerApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'POST',
        url: "/register/register",
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 修改密码
export const updatePasswordApi = (params) => {
    const token = sessionStorage.getItem('token');
    return $axios({
        method: 'PUT',
        url: "/user/updatePassword",
        data: params,
        headers: {
            Authorization: token,
        }
    })
}

// 查询角色下拉框
export const findRoleSelectApi = (params) => {
    const token = sessionStorage.getItem('token');
    return $axios({
        method: 'GET',
        url: "/user/findRoleSelect",
        params,
        headers: {
            Authorization: token,
        }
    })
}

// 设置公司与角色优先级
export const settingPriorityApi = (params) => {
    const token = sessionStorage.getItem('token');
    return $axios({
        method: 'PUT',
        url: "/user/settingPriority",
        data: params,
        headers: {
            Authorization: token,
        }
    })
}

// 删除用户
export const userDeleteApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'DELETE',
        url: "/user/delete",
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}
